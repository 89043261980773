import React, { useState, useEffect } from "react";
// import { Route, Switch } from 'react-router-dom';
import {
    web3,
    // web3getAccount,
    PBXAddress,
    BUSDAddress,
    BlockFundX_Address,
    adminWithdraw,
    WalletAccounts,
    CheckBalance,
    CheckContractBalance,
    CheckBUSDBalance,
    getPBXPrice,
    setPBXPrice,
    myRemainStake,
    blockFundXInfo,
    userInfo
} from "../web3/web3Provider";
import { adminAddress } from '../Config/constant';

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, InputGroup, FormControl, Spinner, Badge } from "react-bootstrap";
import { ArrowRight, } from 'react-bootstrap-icons';
// import { Link } from "react-router-dom";

// import Swal from 'sweetalert2'

import Header from '../Components/Header';
// import FooterAPR from '../Components/FooterAPR';
import PoolsCard from '../Components/PoolsCard';
import {
    blockFundXStorage
} from '../Config/constant';
import Swal from "sweetalert2";


function Staking() {
    const [BlockFundXContractAddress, setBlockFundXContractAddress] = useState("");
    const [amountsWithdraw, setAmountsWithdraw] = useState(0);
    const [PBXBalance, setPBXBalance] = useState("0");
    const [BUSDBalance, setBUSDBalance] = useState(0);
    const [PBXPrices, setPBXPrices] = useState(0);
    const [HolderPBXAddress, setHolderPBXAddress] = useState("0x000000000000000000000000000000000000dead");
    const [HolderPBXBalance, setHolderPBXBalance] = useState(0);
    const [iconSaveModalLoading, setIconSaveModalLoading] = useState(false);
    const [iconWithdrawPBXLoading, setIconWithdrawPBXLoading] = useState(false);
    const [iconWithdrawBUSDLoading, setIconWithdrawBUSDLoading] = useState(false);
    const [iconCheckPBXLoading, setIconCheckPBXLoading] = useState(false);

    const [remainStake, setRemainStake] = useState(0);
    const [poolsInfo, setPoolsInfo] = useState([])
    const [usersInfo, setUsersInfo] = useState([])

    /* Modal State */
    const [show, setShow] = useState(false);
    const handleClose = (poolId) => setShow(false);
    const handleShow = (poolId) => setShow(true);

    const executeAndClose = () => {
        setIconSaveModalLoading(true)
        // console.log(PBXPrices)
        setPBXPrice(PBXPrices).then((resTxn) => {
            Swal.fire({
                icon: "success",
                title: " Set PBX price as [ " + PBXPrices + " ] SUCCESS",
                footer: "<a href='https://bscscan.com/tx/" + resTxn + "' target='_blank'>TXN</a>"
            }).then(() => window.location.reload())
        }).catch((error) => setIconSaveModalLoading(false))
    }

    const _adminWithdraw = async (tokens) => {
        let tokensWithdraw = ""
        if(tokens === "PBX")
        {
            setIconWithdrawPBXLoading(true)
            tokensWithdraw = PBXAddress
        } else {
            setIconWithdrawBUSDLoading(true)

            tokensWithdraw = BUSDAddress
        }

        await adminWithdraw(tokensWithdraw, amountsWithdraw).then((resTXN) => {
            Swal.fire({
                icon: "success",
                title: `Withdraw [ ${amountsWithdraw} ${tokens} ] SUCCESS`,
                footer: "<a href='https://bscscan.com/tx/" + resTXN + "' target='_blank'>TXN</a>"
            }).then(() => window.location.reload())
        }).catch((error) => {
            tokens === "PBX"
                ? setIconWithdrawPBXLoading(false)
                : setIconWithdrawBUSDLoading(false)
        })
    }

    const checkPBXBalance = async () => {
        setIconCheckPBXLoading(true)
        const balancer = await CheckBalance(HolderPBXAddress)
        const balance = web3.utils.fromWei(balancer)
        setHolderPBXBalance(parseFloat(balance).toFixed(2) + " PBX")
        setIconCheckPBXLoading(false)
    }

    const modalEditPool = () => {
        return (
            <>
                <Button className="btn btn-warning btnWeb3 mb-3" onClick={() => handleShow()}>
                    BlockFundX Info
                </Button>

                <Modal show={show} fullscreen={true} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit BlockFundX</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <h5>BlockFundX Contract Address</h5>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <p><a href={"https://bscscan.com/address/" + BlockFundXContractAddress} target="_blank" rel="noreferrer">{BlockFundXContractAddress}</a></p>
                        </InputGroup>
                        <br />
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default">Total PBX Balance</InputGroup.Text>
                            <FormControl
                                className="text-center"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={Number(PBXBalance).toFixed(6).toLocaleString()}
                                readOnly
                            />
                            <InputGroup className="mt-3 mb-3">
                                <FormControl type="number" className="text-center" placeholder="Input Amount to Withdraw PBX" step={0.1} onInput={(e) => setAmountsWithdraw(e.target.value)} />
                                <Button variant="warning" className="btnWeb3 mt-3 w-100" onClick={() => _adminWithdraw("PBX", amountsWithdraw)}>
                                    {iconWithdrawPBXLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""}
                                    Withdraw
                                </Button>
                            </InputGroup>
                        </InputGroup>
                        <br />
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default">Total USDT Balance</InputGroup.Text>
                            <FormControl
                                className="text-center"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={Number(BUSDBalance).toFixed(6).toLocaleString()}
                                readOnly
                            />
                            <InputGroup className="mt-3 mb-3">
                                <FormControl type="number" className="text-center" placeholder="Input Amount to Withdraw USDT" step={0.1} onInput={(e) => setAmountsWithdraw(e.target.value)} />
                                <Button variant="warning" className="btnWeb3 mt-3 w-100" onClick={() => _adminWithdraw("USDT", amountsWithdraw)}>
                                    {iconWithdrawBUSDLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""}
                                    Withdraw
                                </Button>
                            </InputGroup>
                        </InputGroup>
                        <br />
                        <InputGroup className="mt-3 mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className="bg-success text-light">PBX Price</InputGroup.Text>
                            <FormControl
                                className="text-center"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={PBXPrices}
                                onInput={(e) => setPBXPrices(e.target.value)}
                            />
                            <Button variant="warning" className="btnWeb3 mt-3 w-100" onClick={executeAndClose}>
                                {iconSaveModalLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""}
                                Edit PBX Price
                            </Button>
                        </InputGroup>
                        <br />
                        <InputGroup className="mt-3 mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default">Holder PBX Balance</InputGroup.Text>
                            <FormControl type="text" className="text-center" placeholder="Input Holder Address" onInput={(e) => setHolderPBXAddress(e.target.value)} />
                            <InputGroup className="mt-3 mb-3">
                                <FormControl type="text" className="text-center" value={HolderPBXBalance} readOnly />
                                <Button variant="warning" className="btnWeb3 mt-3 w-100" onClick={() => checkPBXBalance()}>
                                    {iconCheckPBXLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""}
                                    Check Balance
                                </Button>
                            </InputGroup>
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="secondary" className="btnWeb3" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    useEffect(() => {
        getPBXPrice().then((res) => setPBXPrices(res))
        myRemainStake().then((res) => setRemainStake(res))
        blockFundXInfo(0).then((res) => setPoolsInfo([res]))
        userInfo(0).then(async (res) => setUsersInfo([res]))
        setBlockFundXContractAddress(BlockFundX_Address)
        CheckContractBalance(BlockFundX_Address).then((_balance) => setPBXBalance(parseFloat(web3.utils.fromWei(_balance)).toFixed(6)))
        // CheckBalance(BlockFundX_Address).then((_balance) => setPBXBalance(parseFloat(web3.utils.fromWei(_balance)).toFixed(6)))
        CheckBUSDBalance(BlockFundX_Address).then((_balance) => setBUSDBalance(parseFloat(web3.utils.fromWei(_balance)).toFixed(6)))
    }, [])

    return (

        <>

            <Header />

            <div className="text-center">
                <p>
                    <img src='pbx.gif' alt="logo" />
                </p>
                <br />
                <br />
                <h2>BlockFundX</h2>
                <br />

                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        color: "rgb(90, 96, 108)",
                    }}
                >
                    {/* <p>
                        <Link to="/list" title="List View">
                            <Button variant="secondary btnWeb3"><List style={{ fontSize: "18px"}} /> List View</Button>
                        </Link>
                        {" "}
                        <Link to="/card" title="Card View">
                            <Button variant="warning btnWeb3"><Box style={{ fontSize: "18px"}} /> Card View</Button>
                        </Link>
                    </p> */}
                    <br />

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <span>
                                    <button className="btn btn-warning btn-sm" style={{ backgroundImage: "none !important" }}>
                                        <small>
                                            #Block {" "}
                                            <Badge bg="dark">1</Badge>
                                        </small>
                                    </button>
                                </span>
                                <span>
                                    {' '}<ArrowRight style={{ fontSize: "24px", fontWeight: "bold" }} />{' '}
                                    <button className="btn btn-warning btn-sm" style={{ backgroundImage: "none !important" }}>
                                        <small>
                                            #Block {" "}
                                            <Badge bg="dark">2</Badge>
                                        </small>
                                    </button>
                                </span>
                                <span>
                                    {' '}<ArrowRight style={{ fontSize: "24px", fontWeight: "bold" }} />{' '}
                                    <button className="btn btn-secondary btn-sm" style={{ backgroundImage: "none !important", cursor: "not-allowed" }} disabled>
                                        <small>
                                            #Block {" "}
                                            <Badge bg="dark">???</Badge>
                                        </small>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <br />
                        <p>
                            {
                                WalletAccounts.toLowerCase() === adminAddress.toLowerCase()
                                    ? modalEditPool()
                                    : ""
                            }
                        </p>
                        <hr />
                    </div>
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            {poolsInfo.length > 0
                                ? poolsInfo.map((item, index) => {
                                    let txtNextBlock = index + 1
                                    // let daysLock = ""

                                    if (item.enable === false) txtNextBlock = "???"

                                    // item.dayslock > 0
                                    //     ? (daysLock =
                                    //         <li class="list-group-item d-flex justify-content-between align-items-center">
                                    //             <b>Time Lock</b>
                                    //             <b class="text-muted">${item.dayslock} Days</b>
                                    //         </li>
                                    //     )
                                    //     : (daysLock = "")
                                        
                                    // if (item.enable === false)
                                    //     return (
                                    //         <PoolsCard
                                    //             poolId={index}
                                    //             nextBlock={txtNextBlock}
                                    //             img={item.img}
                                    //             balance={item.balance}
                                    //             roi={item.roi}
                                    //             winrate={item.winrate}
                                    //             dd={item.dd}
                                    //             PBXPerUnit={item.PBXPerUnit}
                                    //             rewardImg={item.rewardImg}
                                    //             daysLock={item.dayslock}
                                    //             limit={item.limit}
                                    //             currentStaked={0}
                                    //             link={item.link}
                                    //             maxStake={remainStake}
                                    //             enable={item.enable}
                                    //         />
                                    //     )
                                    // else 
                                    return (
                                        <PoolsCard
                                            key={index}
                                            poolId={index}
                                            nextBlock={txtNextBlock}
                                            img={blockFundXStorage(index).profile}
                                            balance={poolsInfo[index].Balance}
                                            userDeposit={usersInfo.deposits}
                                            roi={poolsInfo[index].APRpercent / 100}
                                            winrate={poolsInfo[index].WinPercent / 100}
                                            dd={poolsInfo[index].DDpercent / 100}
                                            PBXPerUnit={poolsInfo[index].PBXPerUnit}
                                            rewardImg={blockFundXStorage(index).rewardImg}
                                            daysLock={poolsInfo[index].DaysLock}
                                            tvl={parseFloat(web3.utils.fromWei(poolsInfo[index].TVL))}
                                            limit={poolsInfo[index].LimitUnitStake !== undefined ? parseFloat(web3.utils.fromWei(poolsInfo[index].LimitUnitStake)) : 0}
                                            currentStaked={poolsInfo[index].TVL !== undefined ? parseFloat(web3.utils.fromWei(poolsInfo[index].TVL)) : 0}
                                            totalHarvest={poolsInfo[index].TotalHarvest}
                                            link={poolsInfo[index].URL}
                                            maxStake={remainStake}
                                            enable={poolsInfo[index].IsFinish === false ? true : false}
                                        />
                                    )
                                })
                                : (
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex">
                                            <Spinner animation="border" variant="warning" size="lg" />
                                            <div style={{ marginLeft: 15, fontSize: 24 }}>Checking Network please wait...</div>
                                        </div>
                                    </div>
                                    )
                            }

                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-md-12">
                                <span className="w-100 d-flex align-items-center justify-content-center text-dark"
                                    style={{ height: "60px", background: "#e3e3e3" }}>
                                    How to invest BlockFundX Step by Step
                                </span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 col-md-4">
                                <a href="https://staking.polarbear100x.co" className="btn btn-light btn-md w-100 d-flex align-items-center justify-content-center text-dark goldenBack btnWeb3"
                                    style={{ marginBottom: "5px", height: "60px" }}>
                                    Step 1. Stake PBX
                                </a>
                            </div>
                            <div className="col-12 col-md-4">
                                <a href="#" className="btn btn-light btn-md w-100 d-flex align-items-center justify-content-center text-dark goldenBack btnWeb3" style={{ marginBottom: "5px", height: "60px", background: "#c3c3c3" }}>
                                    <span>Step 2. Invest BlockFundX</span>
                                </a>
                            </div>
                            <div className="col-12 col-md-4">
                                <a href="#" className="btn btn-light btn-md w-100 d-flex align-items-center justify-content-center text-dark btnWeb3" style={{ marginBottom: "5px", height: "60px", background: "#c3c3c3" }}>
                                    <span>Step 3. Invest MAX allowed <Badge bg="secondary">{remainStake}</Badge> PBX</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <FooterAPR totalTVL={0} /> */}
                </div>
            </div>
            <br /><br />
        </>
    );
}

export default Staking;