import React, { useState } from 'react';
import {
    web3,
    web3getAccount,
    Unstake
} from "../../web3/web3Provider";
import { Modal, Button, InputGroup, Form, FormControl, Spinner } from "react-bootstrap";
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import Swal from 'sweetalert2'

const ModalUninvest = (props) => {
    const [amountUnstake, setAmountUnstake] = useState(0)
    const [iconUnstakeLoading, setIconUnstakeLoading] = useState(false);

    const callUnstake = async (poolsId) => {
        setIconUnstakeLoading(true)
        try {
            await web3getAccount().then(async (res) => {
                await Unstake(poolsId, web3.utils.toWei(amountUnstake.toString()), res).then(async (txn) => {
                    Swal.fire({
                        background: 'black',
                        width: 480,
                        timer: 8 * 1000,
                        html: `
                            <img src='img/harvest-unstake.gif' width="100%">
                        `,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    }).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'UnInvest ' + amountUnstake + ' PBX is done',
                            footer: 'TXN : <a href="https://bscscan.com/tx/' + txn + '" target="_blank" rel="noreferrer">View Transaction</a>'
                        }).then(() => {
                            setIconUnstakeLoading(false)
                            window.location.reload()
                        })
                    })
                })
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: err.message
            }).then(() => setIconUnstakeLoading(false))
        }

        setIconUnstakeLoading(false)
    }

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        UnInvest PBX
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "25px 25px 25px 25px" }}>
                    <InputGroup className="mb-3">
                        <FormControl className="bg-dark text-light text-center btnWeb3" type="number" min="1" step="0.001" value={amountUnstake} onInput={(e) => setAmountUnstake(e.target.value)} placeholder="0" aria-describedby="basic-addon1" />
                        <Button variant="warning" className="goldenBack btnWeb3" onClick={() => setAmountUnstake(props.maxunstake)} >MAX</Button>
                    </InputGroup>
                    <div className="d-flex justify-content-between">
                        <span>Min 0</span>
                        <span>{props.maxunstake} Max</span>
                    </div>
                    <Form.Range min={0} max={props.maxunstake} step={0.001} value={amountUnstake === 0 ? 0 : amountUnstake} onChange={(e) => setAmountUnstake(e.target.value)} />
                    <div className="d-flex justify-content-around">
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxunstake > 0 ? setAmountUnstake(props.maxunstake * 0.25) : 0}>25%</button>
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxunstake > 0 ? setAmountUnstake(props.maxunstake * 0.5) : 0}>50%</button>
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxunstake > 0 ? setAmountUnstake(props.maxunstake * 0.75) : 0}>75%</button>
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxunstake > 0 ? setAmountUnstake(props.maxunstake) : 0}>100%</button>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="warning" className="goldenBack btnWeb3 w-100" style={{ height: "50px" }} onClick={() => callUnstake(props.poolid)} >
                        {iconUnstakeLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowUpRight />{" "}</>)}
                        UnInvest PBX
                    </Button>
                    <Button variant="secondary" className="w-100 btnWeb3" style={{ height: "50px" }} onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>



        </>
    )
}
export {
    ModalUninvest
}