import React, { useState, useEffect } from "react";
import {
    web3,
    web3getAccount,
    WalletAccounts,
    // timeNows,
    totalBlockFundX,
    blockFundXInfo,
    setBlockFundX,
    userInfo,
    totalUserInfo,
    // userPendingReward,
    CheckBalance,
    // chkApprove,
    chkApproveAmount,
    addApprove,
    myRemainStake,
    // Stake,
    Harvest,
    blockFinish
} from "../web3/web3Provider";
import './../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { adminAddress, timeConverter, NumberToDecimal } from '../Config/constant';

// Import Button Components
// import { Uninvest } from './Buttons/Uninvest';
import { ConnectMetamask } from './Buttons/ConnectMetamask';
import { ApprovePool } from './Buttons/ApprovePool';
// import { ModalAPRcal } from './Modals/APRcal';
import { ModalInvest } from './Modals/Invest';
import { ModalUninvest } from './Modals/UnInvest';

import { Link } from 'react-router-dom';
import { Table, Image, Modal, Card, Button, ListGroup, InputGroup, FormControl, Spinner, Collapse, Badge } from "react-bootstrap";
import { Lock, GraphUpArrow, BoxArrowInDownRight, ArrowDown, StarFill, BoxArrowUpRight, CaretDown, CaretUp } from 'react-bootstrap-icons';
// import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
// import { ethers } from "ethers";

function Pools(item) {

    const [open, setOpen] = useState(false);

    const [isUnlock, setIsUnlock] = useState(false);

    // const [PBXPrices, setPBXPrices] = useState(0);

    const [approvedPool, setApprovedPool] = useState(0);
    // const [timesNows, setTimesNows] = useState(0);
    const [maxStake, setmaxStake] = useState(0);
    const [maxUnstake, setmaxUnstake] = useState(0);
    // const [amount, setAmount] = useState(0);
    // const [amountUnstake, setAmountUnStake] = useState(0);
    const [poolsInfo, setPoolsInfo] = useState([])

    const [getTokenStake, setGetTokenStake] = useState("")
    const [getTokenReward, setGetTokenReward] = useState("")
    const [getBalance, setGetBalance] = useState("")
    const [getURL, setGetURL] = useState("")
    const [getAPRpercent, setGetAPRpercent] = useState(0)
    const [getWinPercent, setGetWinPercent] = useState(0)
    const [getDDpercent, setGetDDpercent] = useState(0)
    const [getDaysLock, setGetDaysLock] = useState(0)
    const [getPBXPerUnit, setGetPBXPerUnit] = useState(0)
    const [getLimitUnitStake, setGetLimitUnitStake] = useState("")

    const [usersInfo, setUsersInfo] = useState([])
    const [totalUserInfos, setTotalUserInfos] = useState([])
    // const [pbxIsApprove, setPbxIsApprove] = useState(0);
    // const [pbxIsOldApprove, setPbxIsOldApprove] = useState(0);
    // const [iconApproveLoading, setIconApproveLoading] = useState(false);
    const [iconSaveModalLoading, setIconSaveModalLoading] = useState(false);

    const [iconHarvestLoading, setIconHarvestLoading] = useState(false);
    // const [iconUnstakeLoading, setIconUnstakeLoading] = useState(false);
    // const [increaseApprove, setIncreaseApprove] = useState(false);

    const [pendingRewards, setPendingRewards] = useState(0);

    /* Modal State */
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    // const [modalAPRShow, setModalAPRShow] = useState(false);
    const [modalInvestShow, setModalInvestShow] = useState(false);
    const [modalUnInvestShow, setModalUnInvestShow] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    // const timeConverter = (UNIX_timestamp) => {
    //     const addZero = (i) => {
    //         if (i < 10) { i = "0" + i }
    //         return i;
    //     }
    //     var a = new Date(UNIX_timestamp);
    //     var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep.', 'Oct', 'Nov', 'Dec'];
    //     var year = a.getFullYear();
    //     var month = months[a.getMonth()];
    //     var date = a.getDate();
    //     var hour = addZero(a.getHours());
    //     var min = addZero(a.getMinutes());
    //     // var sec = addZero(a.getSeconds());
    //     var time = date + ' ' + month + ' ' + year + ' - ' + hour + ':' + min;
    //     return time;
    // }


    // const APRcal = () => {
    //     return (
    //         <>
    //             <Button variant="warning" className="btnWeb3" onClick={() => setModalAPRShow(true)}><Calculator /></Button>

    //             <ModalAPRcal
    //                 show={modalAPRShow}
    //                 onHide={() => setModalAPRShow(false)}
    //             />
    //         </>
    //     );
    // }
    const Invest = () => {
        return (
            <>
                <Button variant="warning" className="goldenBack btnWeb3 w-100" style={{ height: "50px", opacity: '50%', cursor: 'not-allowed' }} onClick={() => Swal.fire('Please wait for New Block.', '', 'info')} >
                    <BoxArrowInDownRight />{" "}
                    Invest PBX
                </Button>
                {/* <Button variant="warning" className="goldenBack btnWeb3 w-100" style={{ height: "50px" }} onClick={() => setModalInvestShow(true)} >
                    <BoxArrowInDownRight />{" "}
                    Invest PBX
                </Button>

                <ModalInvest
                    show={modalInvestShow}
                    poolid={item.poolId}
                    approvedpool={approvedPool}
                    maxstake={maxStake}
                    onHide={() => setModalInvestShow(false)}
                /> */}
            </>
        );
    }
    const UnInvest = () => {
        return (
            <>
                <Button variant="warning" className="goldenBack btnWeb3 w-100" style={{ height: "50px" }} onClick={() => setModalUnInvestShow(true)} >
                    <BoxArrowUpRight />{" "}
                    UnInvest PBX
                </Button>

                <ModalUninvest
                    show={modalUnInvestShow}
                    poolid={item.poolId}
                    maxunstake={maxUnstake}
                    onHide={() => setModalUnInvestShow(false)}
                />
            </>
        );
    }

    const executeAndClose = (poolId) => {
        setIconSaveModalLoading(true)
        if (
            getTokenStake === '' ||
            getTokenReward === '' ||
            getBalance === '' ||
            getURL === '' ||
            getAPRpercent === '' ||
            getWinPercent === '' ||
            getDDpercent === '' ||
            getDaysLock === '' ||
            getPBXPerUnit === '' ||
            getLimitUnitStake === ''
        ) {
            Swal.fire({
                icon: "info",
                title: "Input form not empty"
            })
            return;
        }
        // console.log("====================================================")
        // console.log(poolId, inputTokenStake, inputTokenReward, inputAPRpercent, inputDaysLock, inputPBXPerUnit, inputLimitUnitStake)
        setBlockFundX(poolId, getTokenStake, getTokenReward, getBalance, getURL, getAPRpercent, getWinPercent, getDDpercent, getDaysLock, getPBXPerUnit, getLimitUnitStake).then((resTxn) => {
            Swal.fire({
                icon: 'success',
                title: 'SUCCESS',
                text: "BlockFundX has Executes",
                footer: "<a href='https://bscscan.com/tx/" + resTxn + "' target='_blank' rel='noreferrer'>TXN</a>"
            }).then(() => window.location.reload())
        }).catch((error) => {
            setIconSaveModalLoading(false)
            console.log(error.message)
        })

        // console.log("====================================================")

    }

    const modalEditPool = (poolId) => {
        return (
            <>
                <Button className="btn btn-warning btnWeb3 mb-3" onClick={() => handleShow(poolId)}>
                    Edit Block {poolId + 1}
                </Button>

                <Modal show={show} fullscreen={true} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit BlockFundX Id <Badge bg="warning">{poolId + 1}</Badge></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            poolsInfo.length > 0
                                ?
                                (
                                    <>
                                        <InputGroup className="mb-3" style={{ display: "none" }}>
                                            <InputGroup.Text id="inputGroup-sizing-default">BlockFundX Id</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={poolId}
                                                readOnly
                                            />
                                        </InputGroup>
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Token Stake</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getTokenStake}
                                                onInput={(e) => setGetTokenStake(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Token Reward</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getTokenReward}
                                                onInput={(e) => setGetTokenReward(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br />
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Block Balance</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getBalance}
                                                onInput={(e) => setGetBalance(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br />
                                        {/* <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Block URL</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getURL}
                                                onInput={(e) => setGetURL(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br /> */}
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">(%) APR</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getAPRpercent}
                                                onInput={(e) => setGetAPRpercent(e.target.value)}
                                            />
                                        </InputGroup>
                                        <small className="text-danger">*Limit 2 Decimal Only</small>
                                        <br />
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">(%) WinRatio</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getWinPercent}
                                                onInput={(e) => setGetWinPercent(e.target.value)}
                                            />
                                        </InputGroup>
                                        <small className="text-danger">*Limit 2 Decimal Only</small>
                                        <br />
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">(%) Drawdown</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getDDpercent}
                                                onInput={(e) => setGetDDpercent(e.target.value)}
                                            />
                                        </InputGroup>
                                        <small className="text-danger">*Limit 2 Decimal Only</small>
                                        <br />
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Days Lock</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getDaysLock}
                                                onInput={(e) => setGetDaysLock(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">PBX Per Unit</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getPBXPerUnit}
                                                onInput={(e) => setGetPBXPerUnit(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Limit Unit Stake</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={getLimitUnitStake}
                                                onInput={(e) => setGetLimitUnitStake(e.target.value)}
                                            />
                                        </InputGroup>
                                        <br />
                                        <hr />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="inputGroup-sizing-default">Block Enable / Disable</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                defaultValue={item.enable === true ? 'Enable' : 'Disable'}
                                            />
                                        </InputGroup>
                                        <div className="d-flex justify-content-around">
                                            <Button variant='success' onClick={() => blockFinish(item.poolId, 0) }>Set Enable</Button>
                                            <Button variant='danger' onClick={() => blockFinish(item.poolId, 1) }>Set Disable</Button>
                                        </div>
                                        <br />
                                    </>
                                )
                                : ""
                        }
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant="secondary" className="btnWeb3" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="warning" className="btnWeb3" onClick={() => executeAndClose(poolId)}>
                            {iconSaveModalLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""}
                            Save And Executes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


    const modalTotalUsers = (poolId) => {
        return (
            <>
                <Button className="btn btn-primary btnWeb3 mb-3" onClick={() => handleShow2(poolId)}>
                    Total User of Block {poolId + 1}
                </Button>

                <Modal show={show2} fullscreen={true} onHide={handleClose} size="lg">
                    <Modal.Header onClick={() => handleClose2}>
                        <Modal.Title>View Total User of BlockFundX</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (totalUserInfos.length > 0 && WalletAccounts.toLowerCase() === adminAddress.toLowerCase())
                                ?
                                (
                                    <>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>No.</th>
                                                    <th>Staker</th>
                                                    <th>Deposits PBX</th>
                                                    <th>Units</th>
                                                    <th style={{ fontSize: "14px" }}>Pendings Rewards (USDT)</th>
                                                    <th>Last Harvest</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    totalUserInfos.map((item, index) => {
                                                        if (item.deposits !== "0") {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">{index}</td>
                                                                    <td className="text-center"><a href={"https://bscscan.com/address/" + item.staker} target="_blank" rel="noreferrer">{item.staker}</a></td>
                                                                    <td className="text-end">{item.deposits}</td>
                                                                    <td className="text-end">{item.depositUnits}</td>
                                                                    <td className="text-end">{Number(item.pendingsRewards).toFixed(6)}</td>
                                                                    <td className="text-center">
                                                                        <small>
                                                                            {timeConverter(item.lastHarvest)} ( {item.lastHarvest} )
                                                                        </small>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </>
                                )
                                : console.log('')
                        }
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="secondary" className="btnWeb3" onClick={handleClose2}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    const btnIsNotWeb3 = () => {
        return (
            <Link to={{ pathname: "https://metamask.io/download.html" }} target="_parent">
                <Button
                    variant="dark"
                    className="btnWeb3"
                    style={{
                        fontFamily: "Montserrat",
                        padding: "10px 25px"
                    }}
                >
                    <Image src="./metamask.png" height="36" /> Please Install Metamask
                </Button>{' '}
            </Link>
        );
    }


    // const getConnectWallet = () => {
    //     setIsUnlock(true)
    // }



    const callHarvest = async (poolsId) => {
        setIconHarvestLoading(true)
        try {
            await web3getAccount().then(async (res) => {
                await Harvest(poolsId, res).then(async (txn) => {
                    Swal.fire({
                        background: 'black',
                        width: 480,
                        timer: 10 * 1000,
                        html: `
                            <img src='img/harvest-unstake.gif' width="100%">
                        `,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    }).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Get earned USDT is done',
                            footer: 'TXN : <a href="https://bscscan.com/tx/' + txn + '" target="_blank" rel="noreferrer">View Transaction</a>'
                        }).then(() => {
                            setIconHarvestLoading(false)
                            window.location.reload()
                        })
                    })
                }).catch((err) => {
                    const contractMessage = err?.message.replace("Internal JSON-RPC error.", "")

                    Swal.fire({
                        icon: 'error',
                        title: 'EROR',
                        text: JSON.parse(contractMessage).message
                    }).then(() => setIconHarvestLoading(false))
                })
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: err
            }).then(() => setIconHarvestLoading(false))
        }
    }

    useEffect(() => {
        // console.log(usersInfo)
        const myFetch = async () => {
            await web3getAccount().then(async (res) => {
                if (res !== "0x0000000000000000000000000000000000000000") {
                    setIsUnlock(true)
                }
            })
            await blockFundXInfo(0).then((res) => setPoolsInfo([res]))

            await chkApproveAmount().then((res) => setApprovedPool(res) )

            await blockFundXInfo(0).then(async (res) => {
                setPoolsInfo([res])

                setGetTokenStake(res.TokenStake)
                setGetTokenReward(res.TokenReward)
                setGetBalance(res.Balance)
                setGetURL(res.URL)
                setGetAPRpercent((parseInt(res.APRpercent) / 100).toFixed(2))
                setGetWinPercent((parseInt(res.WinPercent) / 100).toFixed(2))
                setGetDDpercent((parseInt(res.DDpercent) / 100).toFixed(2))
                setGetDaysLock(res.DaysLock)
                setGetPBXPerUnit(res.PBXPerUnit)
                setGetLimitUnitStake(web3.utils.fromWei(res.LimitUnitStake))

                if (WalletAccounts.toLowerCase() === adminAddress.toLowerCase()) {
                    let preTotalUserInfos = []
                    for (let iStake = 0; iStake <= Number(res.TotalStaker); iStake++) {
                        try {
                            await totalUserInfo(0, iStake).then(async (res) => {
                                if (res !== undefined) {
                                    let preData = {
                                        staker: res.staker,
                                        deposits: Number(web3.utils.fromWei(res.deposits)).toLocaleString(),
                                        depositUnits: Number(web3.utils.fromWei(res.depositUnits)).toLocaleString(),
                                        lastHarvest: res.lastHarvest,
                                        timelocks: res.timelocks,
                                        pendingsRewards: web3.utils.fromWei(res.pendingsRewards).toLocaleString()
                                    }
                                    preTotalUserInfos.push(preData)
                                } else {
                                    let preData = {
                                        staker: WalletAccounts,
                                        deposits: 0,
                                        depositUnits: 0,
                                        lastHarvest: 0,
                                        timelocks: 0,
                                        pendingsRewards: 0
                                    }
                                    preTotalUserInfos.push(preData)
                                }

                            })
                        } catch (err) {
                            console.log("Not found this user " + iStake)
                        }
                    }
                    setTotalUserInfos(preTotalUserInfos)
                }

            })
            
            await totalBlockFundX()

            const maxBalance = await CheckBalance()
            const chkMaxAllow = await myRemainStake()

            chkMaxAllow >= parseFloat(web3.utils.fromWei(maxBalance.toString()))
                ? setmaxStake(NumberToDecimal(parseFloat(web3.utils.fromWei(maxBalance)), 4))
                : setmaxStake(NumberToDecimal(chkMaxAllow, 4))
            // await CheckBalance().then((res) => {
            //     setmaxStake(parseFloat(web3.utils.fromWei(res)))
            // // })
        }
        // getPBXPrice().then((res) => setPBXPrices(res))
        myFetch()
        // console.log("ApprovedPool = " + approvedPool)

    }, [])

    /* Fetch User Info */
    useEffect(() => {
        const fetUserInfo = async () => {
            try {
                await userInfo(0).then(async (res) => {
                    let preData = {
                        deposits: web3.utils.fromWei(res.deposits),
                        depositUnits: Number(web3.utils.fromWei(res.depositUnits)).toLocaleString(),
                        lastHarvest: res.lastHarvest,
                        timelocks: res.timelocks,
                        pendingsRewards: web3.utils.fromWei(res.pendingsRewards)
                    }

                    setUsersInfo([preData])
                    setmaxUnstake(web3.utils.fromWei(res.deposits))
                    setPendingRewards(Number(preData.pendingsRewards))
                    // if (res.deposits > 0) {
                    // }
                })
            } catch (err) {
                console.log({ "ERROR": err.message })
            }
        }

        fetUserInfo()
        const fetchUserInfo = setInterval(() => { fetUserInfo() }, 3000)
    }, [pendingRewards])

    return (
        <>
            <div className="col-12 col-md-6 col-lg-4" style={{ marginBottom: "10px" }} key={item.poolId}>
                {
                    WalletAccounts.toLowerCase() === adminAddress.toLowerCase()
                        ? modalEditPool(item.poolId)
                        : ""
                }
                &nbsp;&nbsp;
                {
                    (totalUserInfos.length > 0 && WalletAccounts.toLowerCase() === adminAddress.toLowerCase()) ? modalTotalUsers(item.poolId) : ""
                }
                <div className="card">

                    {
                        item.enable === true
                            ? (
                                <div className="card-header goldenBack d-flex justify-content-between">
                                    <small><b>#Block</b></small>
                                    <small><b><Badge bg="dark">{item.nextBlock}</Badge></b></small>
                                </div>
                            )
                            : (
                                <div className="card-header d-flex justify-content-between">
                                    <small><b>#Block</b></small>
                                    <small><b><Badge bg="dark">{item.nextBlock}</Badge></b></small>
                                </div>
                            )
                    }

                    <div className="card-body text-center">
                        <p>
                            <Image src={item.img === "" ? "https://www.robolinked.me/profile/HFRobotics-Index100/account/J" : item.img} className="rounded-circle" alt="Profile image"
                                style={{ width: "100px", height: "100px", border: "5px solid #ccc" }} />
                        </p>
                        <h5>{item.name}</h5>
                        <ListGroup>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>Balance</b>
                                <b className="text-muted">{Number(item.balance).toLocaleString()} $</b>
                            </ListGroup.Item>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>ROI</b>
                                <b className="text-success">{item.roi}% <GraphUpArrow style={{ fontSize: "22px", fontWeight: "bold" }} /></b>
                            </ListGroup.Item>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>Win ratio</b>
                                <b className="text-success">{item.winrate}% <GraphUpArrow style={{ fontSize: "22px", fontWeight: "bold" }} /></b>
                            </ListGroup.Item>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>Drawdown</b>
                                <b className="text-muted">{item.dd}% <ArrowDown style={{ fontSize: "22px", fontWeight: "bold" }} /></b>
                            </ListGroup.Item>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>Invest ratio</b>
                                <b className="text-muted">{item.PBXPerUnit} PBX / Unit</b>
                            </ListGroup.Item>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>Days Lock</b>
                                <b className="text-muted">{item.daysLock} Days</b>
                            </ListGroup.Item>
                            <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                                <b>Reward Token</b>
                                <b className="text-muted">
                                    <Image src={item.rewardImg} style={{ width: "24px", height: "24px" }} /> USDT
                                </b>
                            </ListGroup.Item>
                            {
                                WalletAccounts.toLowerCase() === adminAddress.toLowerCase()
                                    ?
                                    (
                                        <>
                                        <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center text-danger" style={{ border: "none" }}>
                                            <b>Total User Earned</b>
                                            <b>{parseFloat(web3.utils.fromWei(item.totalHarvest)).toFixed(6)} USDT</b>
                                        </ListGroup.Item>
                                        <ListGroup.Item action variant="light" className="list-group-item d-flex justify-content-between align-items-center text-secondary" style={{ border: "none" }}>
                                            <b>Total User Earned (Old)</b>
                                            <b>{(6899.57069).toFixed(6)} USDT</b>
                                        </ListGroup.Item>
                                        </>
                                    )
                                    : ""
                            }
                            <ListGroup.Item action variant="light" className="list-group-item" style={{ border: "none" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <b>Limit Invest</b>
                                    <b className="text-muted">{Number(item.limit).toLocaleString()} Unit</b>
                                </div>
                                <div className="progress" style={{ position: "relative", background: "#ccc", height: "30px" }}>
                                    <div className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                                        style={{ width: `${(item.currentStaked / (item.limit))*100 ?? 0}%` }}></div>
                                    <small className="mt-2 text-light"
                                        style={{ position: "absolute", fontSize: "14px", left: "35%", textShadow: "1px 1px 8px #000000" }}>
                                        {Number(item.currentStaked).toLocaleString()}/{Number(item.limit).toLocaleString()}
                                    </small>
                                </div>
                                <p>
                                    {/* {((item.currentStaked/10) / item.limit) * 100 > 0 ? (((item.currentStaked/10) / item.limit) * 100).toFixed(2) : 0} % */}
                                    {
                                        ((item.currentStaked / (item.limit)) * 100).toFixed(2)
                                    }
                                    %
                                </p>
                            </ListGroup.Item>
                            {/* <ListGroup.Item className="list-group-item" style={{ border: "none" }}>
                                <a href={item.link} target="_blank" rel="noreferrer" className="btn btn-warning btn-md w-100 d-flex align-items-center justify-content-center goldenBack text-dark btnWeb3"
                                    style={{ marginBottom: "5px", height: "50px" }}>
                                    <StarFill /> Follow
                                </a>
                            </ListGroup.Item> */}
                            <ListGroup.Item className="list-group-item" style={{ border: "none" }}>
                                <div className="d-flex justify-content-between goldenBack text-light" style={{ padding: "10px 5px 10px 5px", borderLeft: "4px solid #d8dbe0", marginBottom: "10px", borderRadius: "5px" }}>
                                    <span>My Invested</span>
                                    <span className="text-end">
                                        {
                                            usersInfo.length > 0
                                                ? (<><span>{usersInfo[item.poolId].depositUnits.toLocaleString()} Unit</span><br /><span>( {Number(usersInfo[item.poolId].deposits).toLocaleString()}</span> PBX ) </>)
                                                : 0
                                        }
                                    </span>
                                </div>
                                <span className="text-muted" style={{ fontSize: "12px" }}>
                                    * Calculated limit from your <a href="https://staking.polarbear100x.co">staked PBX</a>
                                </span>
                            </ListGroup.Item>
                        </ListGroup>
                        <br />
                        {/* <div className="btn btn-danger btn-sm btnWeb3 w-100 py-3" style={{ fontSize: 22 }}>
                            We are under maintenance
                        </div> */}
                        {
                            item.enable === true
                                ?
                                typeof window.ethereum === 'undefined'
                                    ? btnIsNotWeb3()
                                    :
                                    isUnlock === true
                                        ?
                                        approvedPool > 0
                                            ?
                                            (
                                                <Button
                                                    className="btn btn-warning goldenBack btn-sm btnWeb3 w-100"
                                                    style={{ height: "50px" }}
                                                    onClick={() => setOpen(!open)}
                                                    aria-controls="collapseMenu"
                                                    aria-expanded={open}
                                                >
                                                    {
                                                        open === false
                                                            ? (<>
                                                                Earn, Invest, UnInvest <CaretDown style={{ fontSize: "18px" }} />
                                                            </>)
                                                            : (<>
                                                                Hides <CaretUp style={{ fontSize: "18px" }} />
                                                            </>)
                                                    }
                                                </Button>
                                            )
                                            :
                                            (
                                                <Card style={{ backgroundColor: "#eff6ff" }}>
                                                    <Card.Body>
                                                        <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>Please Approve</p>
                                                        <p>
                                                            <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => addApprove(parseInt(item.poolId))} >
                                                                <Lock />
                                                                Approve
                                                            </Button>
                                                        </p>

                                                    </Card.Body>
                                                </Card>
                                            )
                                        : (
                                            <ConnectMetamask />
                                        )

                                : (<button className="btn btn-secondary btnWeb3w-100" style={{ cursor: "not-allowed", backgroundImage: "none !important" }} disabled><i className="fa fa-clock-o"></i> BLOCK NOT START</button>)
                        }

                    </div>
                    <Collapse in={open}>
                        <div className="card-footer text-center" id="collapseMenu">
                            {
                                (item.poolId === 0 && usersInfo.length === 1 && approvedPool > 0)
                                    ? (
                                        <>
                                            <Card style={{ backgroundColor: "#eff6ff" }}>
                                                <Card.Body>
                                                    <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>EARNED</p>
                                                    <span id="countPendingRewards">
                                                        {Number(usersInfo[item.poolId].pendingsRewards).toFixed(6).toLocaleString()} USDT
                                                    </span>
                                                    <br /><br />
                                                    <p>
                                                        {
                                                            Number(usersInfo[item.poolId].pendingsRewards) > 0
                                                            ? (<>
                                                                    <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callHarvest(item.poolId)} >
                                                                        {iconHarvestLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowInDownRight />{" "}</>)}
                                                                        Get Earned <img src={item.rewardImg} height="24" alt="rewardImg" className="rounded-circle" style={{ border: "2px solid #fff" }} />
                                                                    </Button>
                                                                </>)
                                                                : (<>
                                                                    <Button variant="warning" className="goldenBack btnWeb3 w-100" disabled >
                                                                        <BoxArrowInDownRight />{" "}
                                                                        Get Earned <img src={item.rewardImg} height="24" alt="rewardImg" className="rounded-circle" style={{ border: "2px solid #fff" }} />
                                                                    </Button>
                                                                </>)
                                                        }
                                                    </p>
                                                    <p className="text-muted mt-3">
                                                        <small>0% fee of Get Earned
                                                            <img src={item.rewardImg} height="24" alt="rewardImg" className="rounded-circle" style={{ border: "2px solid #fff" }} />
                                                        </small>
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                            <hr />
                                        </>)
                                    : (<></>)
                            }

                            {
                                item.enable === true
                                    ? (

                                        approvedPool > 0
                                            ? (
                                                <>
                                                    {
                                                        item.tvl/1000 >= item.limit
                                                        ? (
                                                            <>
                                                                <Button variant="warning" className="goldenBack btnWeb3 w-100" style={{ height: "50px" }} disabled >
                                                                    <BoxArrowInDownRight />{" "}
                                                                    Limit Invest PBX
                                                                </Button>
                                                                <div>{Number(item.tvl)/1000}</div>
                                                                <div>{item.limit}</div>
                                                            </>
                                                        )
                                                        : Invest()
                                                   
                                                    }
                                                    <p className="text-muted mt-3"><small>0% fee of PBX Investing.</small></p>

                                                </>
                                            )
                                            :
                                            typeof window.ethereum === 'undefined'
                                                ? btnIsNotWeb3()
                                                : isUnlock === true ? (<ApprovePool poolId={item.poolId} />) : (<ConnectMetamask />)

                                    )
                                    : (<button className="btn btn-secondary btnWeb3w-100" style={{ cursor: "not-allowed", backgroundImage: "none !important" }} disabled><i className="fa fa-clock-o"></i> BLOCK NOT START</button>)
                            }
                            <hr />
                            {
                                usersInfo.length > 0
                                    ? (
                                        <>
                                            {UnInvest()}
                                            {/* <br/>
                                            <Uninvest poolId={item.poolId} deposits={usersInfo[0].deposits} /> */}
                                            <p className="text-muted mt-3"><small>0.1% fee of PBX UnInvesting.</small></p>
                                        </>)
                                    : (<></>)}
                        </div>
                    </Collapse>
                </div>
            </div>

        </>
    )
}

export default Pools;